// @codekit-prepend "../../node_modules/magnific-popup/dist/jquery.magnific-popup.min.js"
// @codekit-prepend "../../node_modules/slick-carousel/slick/slick.min.js"

(function ($) {
	/* Breakpoints */
	var breakpoints = {
		large: 1200,
		medium: 992,
		small: 768,
		xsmall: 767,
		nav: 1300
	};

	/* Check window width */
	var resizeTimeout = null;
	var cachedWindowWidth = $(window).width();

	// Check if window width has actually changed
	function checkWindowWidth() {
		var newWindowWidth = $(window).width();

		if (newWindowWidth !== cachedWindowWidth) {
			// If changed call functions on resize

			cachedWindowWidth = newWindowWidth;
		}
	}
	$(window).on("resize", function () {
		if (resizeTimeout !== null) {
			clearTimeout(resizeTimeout);
		}

		resizeTimeout = setTimeout(checkWindowWidth, 50);
	});

	/* Fix ADA errors/issues */
	function adaFixes() {
		// Check if Google ReCPATCHA textbox exists, if it does add aria-label attribute and value
		var startTime = new Date().getTime();

		var checkGoogleElementsExist = setInterval(function () {
			// Clear interval no matter what after 5 seconds
			if (new Date().getTime() - startTime > 5000) {
				clearInterval(checkGoogleElementsExist);
				return;
			}

			var captchaResponse = $("#g-recaptcha-response");

			if (captchaResponse.length) {
				captchaResponse.each(function () {
					$(this).attr("aria-label", "Google Captcha Response");
				});

				clearInterval(checkGoogleElementsExist);
			}
		}, 250);
	}
	adaFixes();

	/* Format phone numbers */
	// Strip non-numeric characters from phone numbers and add to href if element contains `tel` class
	function formatPhoneNumbers() {
		var phoneNumber;
		var phoneNumbers = $(".tel");
		var extension = $(".tel + .ext");

		phoneNumbers.each(function () {
			if (extension.length) {
				phoneNumber =
					"tel:1" +
					$(this).text().replace(/\D/g, "") +
					";ext=" +
					extension.data("ext");
			} else {
				phoneNumber = "tel:1" + $(this).text().replace(/\D/g, "");
			}

			$(this).attr("href", phoneNumber);
		});
	}
	formatPhoneNumbers();

	/* Format email addresses */
	// Add email addresses to href if element contains `eml` class
	// function formatEmailAddresses() {
	//   var emailAddress;
	//   var emailAddresses = $('.eml');

	//   emailAddresses.each(function() {
	//     emailAddress = 'mailto:' + $(this).text();
	//     $(this).attr('href', emailAddress);
	//   });
	// }
	// formatEmailAddresses();

	/* Form Fixes */
	// Add `arrow-right` icon to all Gravity Forms submit buttons
	// function addFormSubmitButtonIcons() {
	//   var submitButtonEls = $('.newsletter-sign-up-form .gform_button');

	//   submitButtonEls.each(function() {
	//     $(this).append('<span class="icon-arrow-right"></span>');
	//   });
	// }
	// addFormSubmitButtonIcons();

	// Disable first option (placeholder) in select fields
	// function disableSelectFirstOption() {
	//   $('.gform_wrapper select').attr('required', true);
	//   $('.gform_wrapper select option:first-of-type').not('.gform_wrapper select[multiple] option:first-of-type').attr('disabled', true)
	//     .attr('hidden', true)
	//     .css('display', 'none');
	// }
	// disableSelectFirstOption();

	// Remove text from Newsletter submit button
	// var newsletterSignUpButton = $('.site-footer .newsletter-sign-up-form button');

	// newsletterSignUpButton.contents().filter(function () {
	//   return this.nodeType === 3;
	// }).remove();
	// newsletterSignUpButton.append('<span class="screen-reader-text">Subscribe</span>');

	/* Equal height elements */
	// function equalHeightElements( el, windowWidth ) {
	//   // Reset height
	//   el.css('height', 'auto');

	//   if (cachedWindowWidth >= windowWidth) {
	//     // Get array of element heights
	//     var elHeights = el.map(function() {
	//       return $(this).height();
	//     }).get();

	//     // Get max height from array of heights
	//     var maxHeight = Math.max.apply(null, elHeights);

	//     // Set height of all elements to maxHeight
	//     el.height(maxHeight);
	//   }
	// }
	// if ($('').length) {
	//   equalHeightElements($(''), 768);
	// }

	/* Header */
	// Mobile Navigation
	$(".mobile-nav-btn, .mobile-nav-overlay").on("click", function () {
		$("body").toggleClass("show-menu");
		$(".mobile-nav-btn").toggleClass("is-open");
		$(".menu-item-has-children").removeClass("show-sub-menu");
	});

	// Wrap links that have children with span
	// $('.site-header .navigation .menu-item-has-children > a').wrapInner('<span class="parent-link"></span>');

	// Add event handler to these spans so when clicked they go to the page, they are inline so only clicking the word will link, anywhere else will open the submenu instead
	// $('.site-header .navigation .parent-link').click(function() {
	//   // Go to href of parent
	//   window.location.href = $(this).parent().attr('href');

	//   // Prevent submenu from opening
	//   $(this).parent().parent().toggleClass('show-sub-menu');
	// });

	// Mobile Navigation - Sub menus
	$(".site-header .navigation .menu-item-has-children > a").on(
		"click",
		function (e) {
			if ($(window).width() < breakpoints.nav) {
				e.stopPropagation();
				e.preventDefault();

				var parentMenu = $(this)[0].outerHTML;

				$(this).parent().toggleClass("show-sub-menu");

				if (!$(this).parent().hasClass("has-back-btn")) {
					$(this)
						.next(".sub-menu")
						.prepend(
							'<li class="sub-menu-parent">' +
								parentMenu +
								"</li>"
						)
						.prepend(
							'<li class="sub-menu-back"><a href="#">Back</a></li>'
						);
					$(this).parent().addClass("has-back-btn");
				}
			}
		}
	);

	// Mobile Navigation - Click function for inserted back button
	$(".site-header .navigation .sub-menu").on(
		"click",
		"> .sub-menu-back",
		function (e) {
			e.preventDefault();
			$(this).parent().parent().toggleClass("show-sub-menu");
		}
	);

	/* Popups */
	// Image popup
	$(".image-link").magnificPopup({
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close"></span>',
		type: "image"
	});

	// Reserve apartment popup
	$(".reserve-apartment-link").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to mfp-container element for css
				var reserveApartmentPopup = $(".reserve-apartment-popup");

				if (reserveApartmentPopup.length) {
					reserveApartmentPopup
						.parent()
						.parent()
						.addClass("mfp-reserve-apartment");
				}
			}
		},
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close"></span>',
		items: {
			src: ".reserve-apartment-popup"
		},
		type: "inline"
	});

	// Schedule tour popup
	$(".schedule-tour-link").magnificPopup({
		callbacks: {
			open: function () {
				// Add class to mfp-container element for css
				var scheduleTourPopup = $(".schedule-tour-popup");

				if (scheduleTourPopup.length) {
					scheduleTourPopup
						.parent()
						.parent()
						.addClass("mfp-schedule-tour");
				}
			}
		},
		closeBtnInside: true,
		closeMarkup: '<span class="icon-close mfp-close"></span>',
		items: {
			src: ".schedule-tour-popup"
		},
		type: "inline"
	});

	/* Sliders */
	// Home Slider
	function homeSlider() {
		$(".home-slider.acf-block .slides").slick({
			adaptiveHeight: true,
			appendArrows: $(
				".home-slider.acf-block .slider-controls > .arrows"
			),
			appendDots: $(".home-slider.acf-block .slider-controls > .pager"),
			arrows: false,
			autoplay: true,
			autoplaySpeed: 5000,
			dots: true,
			fade: true,
			// infinite: false,
			mobileFirst: true,
			nextArrow: '<span class="icon-arrow-right"></span>',
			prevArrow: '<span class="icon-arrow-left"></span>',
			responsive: [
				{
					breakpoint: breakpoints.small - 1,
					settings: {
						adaptiveHeight: false,
						arrows: true,
						dots: false
					}
				}
			],
			speed: 500
		});
	}
	if ($(".home-slider.acf-block").length) {
		homeSlider();
	}

	// Gallery slider
	function gallerySlider() {
		$(".gallery-slider.acf-block .slides").each(function () {
			$(this).slick({
				appendArrows: $(this).next().find(".arrows"),
				appendDots: $(this).next().find(".pager"),
				arrows: false,
				// autoplay: true,
				autoplaySpeed: 5000,
				// centerMode: true,
				// centerPadding: '0',
				dots: true,
				// fade: true,
				infinite: true,
				mobileFirst: true,
				nextArrow: '<span class="icon-arrow-right"></span>',
				prevArrow: '<span class="icon-arrow-left"></span>',
				responsive: [
					{
						breakpoint: breakpoints.small - 1,
						settings: {
							arrows: true,
							dots: false
						}
					}
				],
				slidesToShow: 1,
				speed: 400
			});
		});
	}
	if ($(".gallery-slider.acf-block").length) {
		gallerySlider();
	}

	/* Misc */
	// Add multi-color check mark icon to unordered list items
	function addCheckMarkBullets() {
		$("ul.check-marks > li").each(function () {
			var current_html = $(this).html();

			$(this).html(
				'<span class="icon-check-mark icon"><span class="path1"></span><span class="path2"></span></span><span class="text">' +
					current_html +
					"</span>"
			);
		});
	}
	if ($("ul.check-marks").length) {
		addCheckMarkBullets();
	}

	// Add disc icon to second level unordered list items
	function addDiscBullets() {
		$("ul.check-marks ul > li").each(function () {
			var current_html = $(this).html();

			$(this).html(
				'<span class="icon-bullet"></span></span><span class="text">' +
					current_html +
					"</span>"
			);
		});
	}
	if ($("ul.check-marks ul").length) {
		addDiscBullets();
	}
})(jQuery);
